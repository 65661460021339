import React, { useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";
import classnames from "classnames/bind";

import styles from "./b2x-telia-color-dot.module.scss";

const cx = classnames.bind(styles);
const rootClassName = "b2x-telia-color-dot";

export const COLOR_DOT_SIZE = {
  EXTRA_SMALL: "xs",
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
} as const;
export const colorDotSizes = Object.values(COLOR_DOT_SIZE);
export type ColorDotSize = typeof COLOR_DOT_SIZE[keyof typeof COLOR_DOT_SIZE];

type Props = {
  ["data-testid"]?: string;
  className?: string;
  color?: string;
  size?: ColorDotSize;
  withBorder?: boolean;
};

export const TeliaColorDot = ({
  color,
  ["data-testid"]: dataTestid,
  size = COLOR_DOT_SIZE.MEDIUM,
  withBorder,
  className,
}: Props) => {
  useEffect(() => {
    obsoleteWarning(rootClassName, "components-colordot--docs");
  }, []);

  return (
    <div
      data-testid={dataTestid}
      className={cx(className, [styles[rootClassName]], styles[`${rootClassName}--${size}`], {
        [styles[`${rootClassName}--with_border`]]: withBorder,
      })}
      style={{ backgroundColor: color }}
    />
  );
};
